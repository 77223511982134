<template>
  <div class="signup-bg d-flex justify-content-center">
    <overlay-component :isLoading="overlayLoading" />
    <div class="circle-orange"></div>
    <b-row class="d-flex align-items-center px-2 p-lg-5 form-box">
      <b-col md="12" class="px-xl-2 mx-auto">
        <signup-progress-component />

        <!-- form -->
        <validation-observer ref="signupValidation">
          <b-form
            autocomplete="off"
            class="auth-signup-form mt-2 mx-auto"
            @submit.prevent
          >
            <signup-info-component :form_data="form_data" />
            <b-button
              type="submit"
              variant="primary"
              block
              class="btn-signup mx-auto"
              @click="validationForm"
            >
              {{ $t("auth.signup") }}
            </b-button>
          </b-form>
        </validation-observer>

        <b-card-text class="text-center my-3">
          <b-link :to="{ name: 'Login' }">
            <span class="btn-login text-center">{{
              $t("auth.back_to_login")
            }}</span>
          </b-link>
        </b-card-text>
      </b-col>
    </b-row>
    <div class="circle-mix"></div>
  </div>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import {
  BRow,
  BCol,
  BLink,
  BCardText,
  BCardTitle,
  BForm,
  BButton,
} from "bootstrap-vue";
import OverlayComponent from "@/components/shared/OverlayComponent";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import SignupProgressComponent from "@/components/signup/Progress/SignupProgressComponent.vue";
import SignupInfoComponent from "@/components/signup/Info/SignupInfoComponent.vue";

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BCardText,
    BCardTitle,
    BForm,
    BButton,
    ValidationObserver,
    OverlayComponent,
    SignupProgressComponent,
    SignupInfoComponent,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      overlayLoading: false,
      form_data: {
        first_name: null,
        last_name: null,
        email: null,
        password: null,
        password_confirmation: null,
        phone: null,
        country_id: null,
        country_code: null,
        eduyear_id: null,
        birthdate: null,
      },
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
  methods: {
    validationForm() {
      this.$refs.signupValidation.validate().then((success) => {
        if (success) {
          this.overlayLoading = true;
          let formData = new FormData();
          for (const key in this.form_data) {
            if (this.form_data[key] !== null) {
              formData.append(key, this.form_data[key]);
            }
          }
          this.$http
            .post(`auth/guardian/register`, formData)
            .then((res) => {
              this.overlayLoading = false;
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: this.$t("g.send.successTitle"),
                  icon: "EditIcon",
                  variant: "success",
                  text: res.data.data.message,
                },
              });
              setTimeout(() => {
                this.$router.push({ name: "Login" });
              }, 500);
            })
            .catch((err) => {
              this.overlayLoading = false;
              for (const key in err.response.data.errors) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: this.$t("g.send.errorTitle"),
                    text: err.response.data.errors[key][0],
                    icon: "AlertCircleIcon",
                    variant: "danger",
                  },
                });
              }
            });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/pages/page-auth.scss";
@import "./index.scss";
</style>
