<template>
  <div class="sigup-progress">
    <div class="w-100 image-signup">
      <b-img fluid :src="logoImg" alt="Login" class="d-block m-auto" />
    </div>
    <b-card-text class="mb-2 signup">
      <h1 class="text-center upper h1">{{ $t("auth.signup") }}</h1>
      <p class="text-center">{{ $t("auth.please_enter_your_details") }}</p>
    </b-card-text>
  </div>
</template>

<script>
import { BCardText, BImg } from "bootstrap-vue";
export default {
  components: {
    BCardText,
    BImg,
  },

  data() {
    return {
      logoImg: require(`@/assets/images/futuer-icons/logo.svg`),
    };
  },
};
</script>

<style lang="scss" scoped>
@import "./SignupProgressComponent.scss";
</style>
