<template>
  <div class="signup-info">
    <b-row>
      <b-col md="6">
        <b-form-group label-for="First-name" class="control-input">
          <validation-provider
            #default="{ errors }"
            name="First name"
            rules="required"
          >
            <b-form-input
              id="First-name"
              v-model="form_data.first_name"
              :state="errors.length > 0 ? false : null"
              name="First-name"
              class="edu-years"
              :placeholder="$t('g.firstName')"
            />
            <small class="text-danger">
              {{ errors[0] && $t(`g.alert.${errors[0]}`) }}
            </small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group class="control-input">
          <validation-provider
            #default="{ errors }"
            name="Last name"
            rules="required"
          >
            <b-form-input
              v-model="form_data.last_name"
              :state="errors.length > 0 ? false : null"
              name="Last name"
              :placeholder="$t('g.lastName')"
              class="edu-years"
            />
            <small class="text-danger">
              {{ errors[0] && $t(`g.alert.${errors[0]}`) }}
            </small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group class="control-input">
          <validation-provider
            #default="{ errors }"
            name="email"
            rules="required"
          >
            <b-form-input
              type="email"
              v-model="form_data.email"
              :state="errors.length > 0 ? false : null"
              name="email"
              :placeholder="$t('g.email')"
              class="edu-years"
            />
            <small class="text-danger">
              {{ errors[0] && $t(`g.alert.${errors[0]}`) }}
            </small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group class="control-input">
          <validation-provider #default="{ errors }" name="birthdate">
            <b-form-datepicker
              type="email"
              dropleft
              v-model="form_data.birthdate"
              :state="errors.length > 0 ? false : null"
              name="birthdate"
              :placeholder="$t('g.birthdate')"
              class="birthdate-input"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group class="control-input">
          <validation-provider
            #default="{ errors }"
            name="password"
            rules="required"
            vid="pass"
          >
            <b-form-input
              type="password"
              v-model="form_data.password"
              :state="errors.length > 0 ? false : null"
              name="password"
              class="edu-years"
              :placeholder="$t('g.password')"
            />
            <small class="text-danger">
              {{ errors[0] && $t(`g.alert.${errors[0]}`) }}
            </small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group class="control-input">
          <validation-provider
            #default="{ errors }"
            name="confirm password"
            rules="required|confirmed:pass"
          >
            <b-form-input
              type="password"
              v-model="form_data.password_confirmation"
              :state="errors.length > 0 ? false : null"
              name="confirm password"
              class="edu-years"
              :placeholder="$t('g.confirm_pass')"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group class="control-input">
          <validation-provider
            #default="{ errors }"
            name="Country"
            rules="required"
          >
            <b-form-select
              type="text"
              v-model="form_data.country_id"
              :options="countries"
              :state="errors.length > 0 ? false : null"
              text-field="name"
              value-field="id"
              id="country"
              name="country"
              :placeholder="$t('g.country')"
              class="edu-years"
            />
            <label v-if="!form_data.country_id" class="label">{{
              $t("g.country")
            }}</label>
            <small class="text-danger">
              {{ errors[0] && $t(`g.alert.${errors[0]}`) }}
            </small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group class="control-input">
          <b-input-group>
            <template #prepend>
              <vue-country-code
                ref="vcc"
                @onSelect="onSelect"
                :preferredCountries="['sa', 'eg', 'us']"
                :enabledCountryCode="true"
              >
              </vue-country-code>
            </template>
            <validation-provider
              #default="{ errors }"
              name="phone"
              rules="required|min:9|max:11"
            >
              <b-form-input
                type="number"
                v-model="form_data.phone"
                :state="errors.length > 0 ? false : null"
                name="phone"
                :placeholder="$t('g.phone')"
                class="edu-years"
              />
              <small class="text-danger">
                {{ errors[0] && $t(`g.alert.${errors[0]}`) }}
              </small>
            </validation-provider>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BFormDatepicker,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { required, email, min, max } from "@validations";
import { ValidationProvider } from "vee-validate";
export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BInputGroup,
    ValidationProvider,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BFormDatepicker,
  },
  props: {
    form_data: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      required,
      email,
      countries: null,
      img: null,
    };
  },
  created() {
    this.getCountries();
  },
  methods: {
    getCountries() {
      this.$http
        .get(`web/countries`)
        .then((res) => {
          this.countries = res.data.data;
        })
        .catch((err) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("g.send.errorTitle"),
              text: err.response.data.message,
              icon: "AlertCircleIcon",
              variant: "danger",
            },
          });
          console.log(err);
        });
    },
    onSelect({ name, iso2, dialCode }) {
      this.form_data.country_code = dialCode;
    },
    selectCountry(countryCode, key) {
      this.$refs.vcc.activeCountry.iso2 = countryCode;
      this.$refs.vcc.activeCountry.dialCode = key;
      this.form_data.country_code = countryCode;
    },
  },
  watch: {
    "form_data.country_id": {
      deep: true,
      handler() {
        this.countries.map((item) => {
          if (item.id === this.form_data.country_id) {
            this.selectCountry(item.flag, item.code);
          }
        });
      },
    },
  },
};
</script>

<style lang="scss">
@import "./SignupInfoComponent.scss";
</style>
